<template>
  <div class="template-list">
    <!-- <CRow class="row-list" style="justify-content: flex-start; height: 30px; margin-bottom: 20px">
      <CButton style="margin: 0 20px" color="success">
        Описание комплекса из 1С
      </CButton>

      <router-link :to="`/equipment-items/${$route.params.code}/equipment-item-technical-doc`">
        <CButton style="margin: 0 20px" color="success">
          Монтажная схема (PDF)
        </CButton>
      </router-link>

      <CButton style="margin: 0 20px" color="success">
        Инструкция (PDF)
      </CButton>
    </CRow>
    <CRow class="row-list" style="justify-content: flex-start; height: 30px; margin-bottom: 20px">
      <CButton style="margin: 0 20px; background-color: white; border: 1px solid #ccc">
        Загрузить 3D model (obj + mtl)
      </CButton>
      <CButton style="margin: 0 20px; background-color: white; border: 1px solid #ccc">
        Загрузить монтажную схему (PDF)
      </CButton>

      <CButton style="margin: 0 20px; background-color: white; border: 1px solid #ccc">
        Загрузить инструкцию
      </CButton>
    </CRow> -->
    <CRow>
      <CCol sm="4">
        <CCard>
          <CCardBody>
            <CTabs>
              <CTab
                title="Свойства"
                active
                v-if="equipmentItemMeta.model && equipmentItem"
              >
                <CRow class="mt-40">
                  <CCol tag="label" sm="7" class="col-form-label">
                    <span>Выравнивание комплекса на площадке</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Смещение по x"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentItemMeta.model.position.x"
                      @input="updateModelPosition"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Смещение по y"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentItemMeta.model.position.y"
                      @input="updateModelPosition"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Смещение по z"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentItemMeta.model.position.z"
                      @input="updateModelPosition"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Поворот по x"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentItemMeta.model.rotation.x"
                      @input="updateModelRotation"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Поворот по y"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentItemMeta.model.rotation.y"
                      @input="updateModelRotation"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      label="Поворот по z"
                      :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                      v-model="equipmentItemMeta.model.rotation.z"
                      @input="updateModelRotation"
                    />
                  </CCol>
                </CRow>
                <div v-if="safeZoneMarker">
                  <CRow class="mt-4">
                    <CCol tag="label" sm="7" class="col-form-label">
                      <span v-if="safeZoneMarker.visible"
                        >Скрыть зону безопасности</span
                      >
                      <span v-else> Показать зону безопасности</span>
                    </CCol>
                    <CCol sm="2">
                      <CSwitch
                        class="mr-1"
                        color="primary"
                        :checked.sync="safeZoneMarker.visible"
                        @update:checked="changeSafeZoneMarkerVisibility"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="Размер зоны безопасности вверх"
                        :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                        v-model="equipmentItem.safe_zone_top"
                        @input="updateEquipmentItemSafeZoneMarker"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="Размер зоны безопасности вправо"
                        :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                        v-model="equipmentItem.safe_zone_right"
                        @input="updateEquipmentItemSafeZoneMarker"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="Размер зоны безопасности вниз"
                        :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                        v-model="equipmentItem.safe_zone_bottom"
                        @input="updateEquipmentItemSafeZoneMarker"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="Размер зоны безопасности влево"
                        :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                        v-model="equipmentItem.safe_zone_left"
                        @input="updateEquipmentItemSafeZoneMarker"
                      />
                    </CCol>
                  </CRow>
                </div>
                <div>
                  <CRow class="mt-4">
                    <CCol tag="label" sm="7" class="col-form-label">
                      <span>Дополнительные характеристики</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="% расчета стоимости Монтажа от стоимости комплекса, %"
                        :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                        v-model="equipmentItem.installation_percent"
                        @input="updateInstallationPercent"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        label="Для бетона % расчета стоимости Монтажа от стоимости комплекса, %"
                        :horizontal="{ label: 'col-sm-8', input: 'col-sm-4' }"
                        v-model="equipmentItem.installation_percent_concrete"
                        @input="updateInstallationPercentConcrete"
                      />
                    </CCol>
                  </CRow>
                </div>
              </CTab>
              <CTab title="Детали">
                <div style="height: 600px; overflow: scroll">
                  <CDataTable
                    :items="equipmentItemParts"
                    :fields="partFields"
                    hover
                    border
                    sorter
                    :tableFilter="{
                      label: 'Поиск',
                      placeholder: 'введите код',
                    }"
                  >
                    <template #name="{ item }">
                      <td class="td">
                        {{ item.key }}
                        <CButton v-if="item.visible" @click="hidePart(item)"
                          >Скрыть</CButton
                        >
                        <CButton v-else @click="showPart(item)"
                          >Показать</CButton
                        >
                        <CButton @click="showPartOnly(item)"
                          >Показать только её</CButton
                        >
                        <div v-if="item.validated" style="color: green">
                          Валидирована
                        </div>
                        <div v-if="!item.validated" style="color: red">
                          Не валидирована
                        </div>
                        <div>
                          <a
                            :href="
                              '/admin/equipment/equipmentdetailequipmentpart/?equipment_part__code=' +
                              item.code
                            "
                            target="_blank"
                            >Запчасти</a
                          >         <a
                            :href="
                              '/admin/equipment/equipmentpart/' +
                              item.equipmentPartId +
                              '/change/'
                            "
                            target="_blank"
                            >Деталь</a
                          >
                        </div>
                      </td>
                    </template>
                  </CDataTable>
                </div>
              </CTab>
              <CTab title="Запчасти" active>
                <div style="height: 600px; overflow: scroll">
                  <CDataTable
                    :items="equipmentItemMeshes"
                    :fields="detailFields"
                    hover
                    border
                    sorter
                    :tableFilter="{
                      label: 'Поиск',
                      placeholder: 'введите код',
                    }"
                  >
                    <template #name="{ item }">
                      <td class="td">
                        {{ item.userData.name }}
                        <CButton
                          v-if="item.material.transparent"
                          @click="showDetail(item)"
                          >Показать</CButton
                        >
                        <CButton v-else @click="hideDetail(item)"
                          >Скрыть</CButton
                        >
                        <CButton @click="showDetailOnly(item)"
                          >Показать только её</CButton
                        >
                        <div v-if="isDetailOrphan(item)">
                          прикрепить к детали
                          <CSelect
                            :options="getPartsList(item)"
                            @update:value="attachDetailToPart"
                          >
                          </CSelect>
                        </div>
                      </td>
                    </template>
                  </CDataTable>
                </div>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol sm="8">
        <CTabs>
          <CTab title="Модель" active>
            <div id="canvas"></div>
          </CTab>
          <CTab title="Требование на склад">
            <EquipmentItemParts
              v-if="
                equipmentParts &&
                equipmentDetails &&
                equipmentItem &&
                equipmentPackages
              "
              :equipmentParts="equipmentParts"
              :equipmentPartsByItem="equipmentPartsByItem"
              :equipmentDetails="equipmentDetails"
              :equipmentPackages="equipmentPackages"
              :partsMap="equipmentItem.parts_map"
            />
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
  </div>
</template>

<style>
html,
body {
  margin: 0;
}

#canvas {
  display: block;
  min-width: 500px;
  min-height: 75vh;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

#gui {
  position: absolute;
  top: 0;
  right: 0;
}
</style>


<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";

import EquipmentItemParts from "../../components/EquipmentItemParts.vue";

String.prototype.rsplit = function (sep, maxsplit) {
  let split = this.split(sep);
  return maxsplit
    ? [split.slice(0, -maxsplit).join(sep)].concat(split.slice(-maxsplit))
    : split;
};

const detailFields = [
  {
    key: "name",
    label: "Код запчасти",
  },
];

const partFields = [
  {
    key: "name",
    label: "Код  детали",
  },
];

export default {
  name: "EqipmentItem",
  components: {
    EquipmentItemParts,
  },
  data() {
    return {
      el: null,
      renderer: null,
      scene: null,
      lights: {
        ambient: null,
        directional: null,
      },
      cameras: {
        perspective: null,
      },
      controls: {
        perspective: null,
      },
      gltfLoader: null,
      geometries: {},
      materials: {},
      palette: {},
      platform: null,
      platformCenter: null,
      equipmentItem: null,
      safeZoneMarker: null,
      equipmentItemMeta: {
        model: null,
      },
      equipmentItemMeshes: [],
      equipmentItemParts: [],
      detailFields: detailFields,
      partFields: partFields,
      storeSubscription: null,
      equipmentItemLoaded: false,
      animationFrameId: null,
      equipmentPackages: null,
      equipmentDetails: null,
      equipmentParts: null,
      equipmentPartsByItem: null,
    };
  },

  computed: {
    ...mapState({
      equipmentItems: (state) => state.equipmentItems.all,
    }),
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "equipmentItems/getAllSuccess") {
        if (!this.equipmentItemLoaded) {
          for (const equipmentItem of mutation.payload) {
            if (equipmentItem.code === this.$route.params.code) {
              this.equipmentItem = equipmentItem;
              this.gltfLoader.load(equipmentItem.model_constructor, (gltf) => {
                const scene = gltf.scene || gltf.scenes[0];
                this.processEquipmentItemModel(equipmentItem, scene);
                this["equipmentParts/getAll"]();
                this["equipmentDetails/getAll"]();
                this["equipmentParts/getByEquipmentItemId"](equipmentItem.id);
                this["equipmentPackages/getByEquipmentItemId"](
                  equipmentItem.id
                );
                this.equipmentItemLoaded = true;
              });
              break;
            }
          }
        }
      }
      if (mutation.type === "equipmentParts/getAllSuccess") {
        this.equipmentParts = mutation.payload;
        for (const equipmentPart of mutation.payload) {
          const foundEquipmentParts = this.getEquipmentItemPartsByCode(
            equipmentPart.code
          );

          for (const foundEquipmentPart of foundEquipmentParts) {
            foundEquipmentPart.validated = equipmentPart.validated;
            foundEquipmentPart.code = equipmentPart.code;
            foundEquipmentPart.equipmentPartId = equipmentPart.id;
          }
        }
        this.equipmentItemParts = [...this.equipmentItemParts];
      }
      if (mutation.type === "equipmentDetails/getAllSuccess") {
        this.equipmentDetails = mutation.payload;
      }
      if (mutation.type === "equipmentParts/getByEquipmentItemIdSuccess") {
        this.equipmentPartsByItem = mutation.payload;
      }
      if (mutation.type === "equipmentPackages/getByEquipmentItemIdSuccess") {
        this.equipmentPackages = mutation.payload;
      }
    });
    this["equipmentItems/getAll"]();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    ...mapActions([
      "equipmentItems/getAll",
      "equipmentItems/update",
      "equipmentParts/getAll",
      "equipmentDetails/getAll",
      "equipmentParts/getByEquipmentItemId",
      "equipmentPackages/getByEquipmentItemId",
    ]),

    disposeMaterials() {
      this.materials.platformDot.dispose();
      this.materials.defaultMaterial.dispose();
      this.materials.platform.dispose();
      this.scene.traverse((object) => {
        if (object.userData) {
          if (object.userData.originalMaterial) {
            object.userData.originalMaterial.dispose();
          }
          if (object.userData.hiddenMaterial) {
            object.userData.hiddenMaterial.dispose();
          }
        }
        if (object.material) {
          object.material.dispose();
        }
      });
    },

    disposeGeometries() {
      this.geometries.platformDot.dispose();
      this.geometries.platform.dispose();
      this.scene.traverse((object) => {
        if (object.material) {
          object.geometry.dispose();
        }
      });
    },

    hideMesh(mesh) {
      mesh.material = mesh.userData.hiddenMaterial;
    },

    showMesh(mesh) {
      mesh.material = mesh.userData.originalMaterial;
    },

    hideDetail: function (item) {
      this.hideMesh(item);
    },

    showDetail: function (item) {
      this.showMesh(item);
    },

    showDetailOnly: function (item) {
      for (const mesh of this.equipmentItemMeta.model.children) {
        if (mesh.name === item.name) {
          this.showMesh(mesh);
        } else {
          this.hideMesh(mesh);
        }
      }
    },

    partHasDetail(equipmentItemPartKey, equipmentItemDetailKey) {
      for (const testDetailKey of this.equipmentItem.parts_map[
        equipmentItemPartKey
      ]) {
        if (testDetailKey === equipmentItemDetailKey) {
          return true;
        }
      }
      return false;
    },

    isDetailOrphan(item) {
      for (const equipmentItemPartKey in this.equipmentItem.parts_map) {
        if (this.partHasDetail(equipmentItemPartKey, item.userData.name)) {
          return false;
        }
      }
      return true;
    },

    getPartsList(item) {
      const list = [];
      list.push({
        value: "None|None",
        label: "Выберите",
      });
      for (const equipmentItemPartKey in this.equipmentItem.parts_map) {
        list.push({
          value: `${equipmentItemPartKey}|${item.name}`,
          label: equipmentItemPartKey,
        });
      }
      return list;
    },

    getEquipmentItemDetailByKey(equipmentItemDetailKey) {
      for (const equipmentItemDetail of this.equipmentItemMeshes) {
        if (equipmentItemDetailKey === equipmentItemDetail.userData.name) {
          return equipmentItemDetail;
        }
      }
      return null;
    },

    getEquipmentItemPartByKey(equipmentItemPartKey) {
      for (const equipmentItemPart of this.equipmentItemParts) {
        if (equipmentItemPartKey === equipmentItemPart.key) {
          return equipmentItemPart;
        }
      }
      return null;
    },

    getEquipmentItemPartsByCode(equipmentItemPartCode) {
      const result = [];
      for (const equipmentItemPart of this.equipmentItemParts) {
        const foundEquipmentItemPartCodes = equipmentItemPart.key.rsplit(
          "_",
          1
        );
        const foundEquipmentItemPartCode =
          foundEquipmentItemPartCodes.length > 0
            ? foundEquipmentItemPartCodes[0]
            : "";
        if (foundEquipmentItemPartCode === equipmentItemPartCode) {
          result.push(equipmentItemPart);
        }
      }
      return result;
    },

    attachDetailToPart(value) {
      const [equipmentItemPartKey, equipmentItemDetailKey] = value.split("|");
      if (equipmentItemPartKey === "None") {
        return;
      }
      for (const testEquipmentItemPartKey in this.equipmentItem.parts_map) {
        if (equipmentItemPartKey === testEquipmentItemPartKey) {
          this.equipmentItem.parts_map[equipmentItemPartKey].push(
            equipmentItemDetailKey
          );
          this.requestEquipmentItemUpdate();
        }
      }
      const equipmentItemPart =
        this.getEquipmentItemPartByKey(equipmentItemPartKey);
      const equipmentItemDetail = this.getEquipmentItemDetailByKey(
        equipmentItemDetailKey
      );
      if (equipmentItemPart.visible) {
        this.showMesh(equipmentItemDetail);
      } else {
        this.hideMesh(equipmentItemDetail);
      }
    },

    requestEquipmentItemUpdate: debounce(function () {
      this.updateEquipmentItem();
    }, 1000),

    updateEquipmentItem() {
      this["equipmentItems/update"]({
        id: this.equipmentItem.id,
        parts_map: this.equipmentItem.parts_map,
        scene_position_x: this.equipmentItemMeta.model.position.x,
        scene_position_y: this.equipmentItemMeta.model.position.y,
        scene_position_z: this.equipmentItemMeta.model.position.z,
        scene_rotation_x: this.equipmentItemMeta.model.rotation.x,
        scene_rotation_y: this.equipmentItemMeta.model.rotation.y,
        scene_rotation_z: this.equipmentItemMeta.model.rotation.z,
        safe_zone_top: this.equipmentItem.safe_zone_top,
        safe_zone_right: this.equipmentItem.safe_zone_right,
        safe_zone_bottom: this.equipmentItem.safe_zone_bottom,
        safe_zone_left: this.equipmentItem.safe_zone_left,
        installation_percent: this.equipmentItem.installation_percent,
        installation_percent_concrete: this.equipmentItem.installation_percent_concrete,
      });
    },

    hidePart: function (item) {
      item.visible = false;
      for (const i in this.equipmentItemMeta.model.children) {
        const mesh = this.equipmentItemMeta.model.children[i];
        if (this.partHasDetail(item.key, mesh.userData.name)) {
          this.hideMesh(mesh);
        }
      }
    },

    showPart: function (item) {
      item.visible = true;
      for (const i in this.equipmentItemMeta.model.children) {
        const mesh = this.equipmentItemMeta.model.children[i];
        if (this.partHasDetail(item.key, mesh.userData.name)) {
          this.showMesh(mesh);
        }
      }
    },

    showPartOnly: function (item) {
      for (const equipmentItemPartKey in this.equipmentItemParts) {
        const equipmentItemPart = this.equipmentItemParts[equipmentItemPartKey];
        if (equipmentItemPart.key === item.key) {
          equipmentItemPart.visible = true;
        } else {
          equipmentItemPart.visible = false;
        }
      }
      for (const i in this.equipmentItemMeta.model.children) {
        const mesh = this.equipmentItemMeta.model.children[i];
        this.hideMesh(mesh);
      }
      for (const i in this.equipmentItemMeta.model.children) {
        const mesh = this.equipmentItemMeta.model.children[i];
        if (this.partHasDetail(item.key, mesh.userData.name)) {
          this.showMesh(mesh);
        }
      }
    },

    initCanvas: function () {
      this.el = document.getElementById("canvas");
      this.viewportWidth = this.el.clientWidth;
      this.viewportHeight = this.el.clientHeight;
    },

    initRenderer: function () {
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
      });
      this.renderer.physicallyCorrectLights = true;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.setClearColor(0xffffff);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.viewportWidth, this.viewportHeight);
      this.el.appendChild(this.renderer.domElement);

      this.renderer.capabilities.getMaxAnisotropy();
    },

    initScene: function () {
      this.scene = new THREE.Scene();
    },

    initLights: function () {
      this.lights.ambient = new THREE.AmbientLight(0xffffff, 2);
      this.scene.add(this.lights.ambient);

      this.lights.directional = new THREE.DirectionalLight(0xffffff, 4);
      this.scene.add(this.lights.directional);
    },

    initPlatformCenter() {
      // this.platformCenter = new THREE.Mesh(
      //   this.geometries.platformDot,
      //   this.materials.platformDot
      // );
      // this.scene.add(this.platformCenter);
      this.scene.add(new THREE.AxesHelper(100));
    },

    initModelLoader: function () {
      THREE.ImageUtils.crossOrigin = "";
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onProgress = function () {};
      loadingManager.onError = function () {};
      this.gltfLoader = new GLTFLoader(loadingManager);
    },

    initPerspectiveCamera: function () {
      const width = this.viewportWidth;
      const height = this.viewportHeight;
      this.cameras.perspective = new THREE.PerspectiveCamera(
        45,
        width / height,
        0.001,
        10000
      );
      this.cameras.perspective.position.y = 5;
      this.cameras.perspective.position.z = 11;
      this.cameras.perspective.updateProjectionMatrix();
    },

    initPerspectiveControls: function () {
      this.controls.perspective = new OrbitControls(
        this.cameras.perspective,
        this.renderer.domElement
      );
      this.controls.perspective.enabled = true;
      this.controls.perspective.update();
    },

    initGeometries: function () {
      this.geometries.platformDot = new THREE.SphereGeometry(0.1, 10, 10);
      this.geometries.platform = new THREE.PlaneGeometry(10, 10);
    },

    initPalette: function () {
      this.palette.platformDot = new THREE.Color(0xffffff);
      this.palette.validSafeZoneMarkerPlacementColor = new THREE.Color();
      this.palette.validSafeZoneMarkerPlacementColor.setHSL(3 / 8, 1, 0.5);
    },

    initMaterials: function () {
      this.materials.platformDot = new THREE.MeshBasicMaterial({
        color: this.palette.platformDot,
      });
      this.materials.defaultMaterial = new THREE.MeshBasicMaterial({
        color: this.palette.platformDot,
      });
      this.materials.platform = new THREE.MeshStandardMaterial({
        color: 0x3fb27f,
        side: THREE.DoubleSide,
      });
      this.materials.validSafeZoneMarker = new THREE.MeshPhongMaterial({
        color: this.palette.validSafeZoneMarkerPlacementColor,
        opacity: 0.5,
        transparent: true,
      });
    },

    initPlatform: function () {
      this.platform = new THREE.Mesh(
        this.geometries.platform,
        this.materials.platform,
        true
      );
      this.platform.rotation.x = Math.PI / 2;
      this.scene.add(this.platform);
    },

    initSafeZoneMarker() {
      const safeZoneMarkerBox = new THREE.Box3().setFromObject(
        this.equipmentItemMeta.model
      );

      const safeZoneMarkerSize = new THREE.Vector3();
      safeZoneMarkerBox.getSize(safeZoneMarkerSize);

      const safeZoneTop = parseFloat(this.equipmentItem.safe_zone_top);
      const safeZoneRight = parseFloat(this.equipmentItem.safe_zone_right);
      const safeZoneBottom = parseFloat(this.equipmentItem.safe_zone_bottom);
      const safeZoneLeft = parseFloat(this.equipmentItem.safe_zone_left);

      const offsetX = -((safeZoneLeft - safeZoneRight) / 2);
      const offsetY = (safeZoneMarkerSize.y + 0.5) / 2;
      const offsetZ = -((safeZoneTop - safeZoneBottom) / 2);

      const x = safeZoneLeft + safeZoneRight + safeZoneMarkerSize.x;
      const y = safeZoneMarkerSize.y + 0.5;
      const z = safeZoneTop + safeZoneBottom + safeZoneMarkerSize.z;
      const geometry = new THREE.BoxGeometry(x, y, z);
      this.safeZoneMarker = new THREE.Mesh(
        geometry,
        this.materials.validSafeZoneMarker
      );
      this.safeZoneMarker.position.x = offsetX;
      this.safeZoneMarker.position.y = offsetY;
      this.safeZoneMarker.position.z = offsetZ;
      this.safeZoneMarker.visible = false;
      this.scene.add(this.safeZoneMarker);
    },

    disposeSafeZoneMarker() {
      this.scene.remove(this.safeZoneMarker);
      this.safeZoneMarker = null;
    },

    init() {
      this.initCanvas();
      this.initRenderer();
      this.initScene();
      this.initLights();
      this.initModelLoader();
      this.initPerspectiveCamera();
      this.initPerspectiveControls();
      this.initGeometries();
      this.initPalette();
      this.initMaterials();
      this.initPlatform();
      this.initPlatformCenter();
    },

    dispose() {
      this.disposeMaterials();
      this.disposeGeometries();
    },

    addEquipmentItemToScene(equipmentItem, object) {
      const equipmentItemToAdd = {
        item: equipmentItem,
        model: object,
      };
      equipmentItemToAdd.model.position.x = equipmentItem.scene_position_x;
      equipmentItemToAdd.model.position.y = equipmentItem.scene_position_y;
      equipmentItemToAdd.model.position.z = equipmentItem.scene_position_z;
      equipmentItemToAdd.model.rotation.x = equipmentItem.scene_rotation_x;
      equipmentItemToAdd.model.rotation.y = equipmentItem.scene_rotation_y;
      equipmentItemToAdd.model.rotation.z = equipmentItem.scene_rotation_z;
      this.scene.add(equipmentItemToAdd.model);
      return equipmentItemToAdd;
    },

    processEquipmentItemMeshes(object, meshMaterials) {
      for (const mesh of object.children) {
        if (mesh.children.length > 0) {
          this.processEquipmentItemMeshes(mesh, meshMaterials);
        } else {
          mesh.userData.originalMaterial = mesh.material;
          let hiddenMaterial;
          if (!meshMaterials[mesh.material.uuid]) {
            hiddenMaterial = mesh.material.clone();
            hiddenMaterial.opacity = 0.2;
            hiddenMaterial.transparent = true;
            meshMaterials[mesh.material.uuid] = hiddenMaterial;
          }
          mesh.userData.hiddenMaterial = meshMaterials[mesh.material.uuid];
          this.equipmentItemMeshes.push(mesh);
        }
      }
    },

    processEquipmentItemModel: function (model, object) {
      this.equipmentItemMeta = this.addEquipmentItemToScene(model, object);
      this.initSafeZoneMarker();
      const meshMaterials = {};

      this.processEquipmentItemMeshes(object, meshMaterials);
      for (const partKey in this.equipmentItem.parts_map) {
        this.equipmentItemParts.push({
          key: partKey,
          visible: true,
          validated: false,
        });
      }
    },

    updateModelPosition() {
      this.requestEquipmentItemUpdate();
    },

    updateModelRotation() {
      this.requestEquipmentItemUpdate();
    },

    updateEquipmentItemSafeZoneMarker() {
      this.disposeSafeZoneMarker();
      this.initSafeZoneMarker();
      this.safeZoneMarker.visible = true;
      this.requestEquipmentItemUpdate();
    },

    updateInstallationPercent() {
      this.requestEquipmentItemUpdate();
    },

    updateInstallationPercentConcrete() {
      this.requestEquipmentItemUpdate();
    },

    changeSafeZoneMarkerVisibility() {},

    animate: function () {
      this.animationFrameId = requestAnimationFrame(this.animate);
      this.controls.perspective.update();
      this.renderer.render(this.scene, this.cameras.perspective);
    },
  },

  mounted() {
    this.init();
    this.animate();
  },
  destroyed() {
    cancelAnimationFrame(this.animationFrameId);
  },
};
</script>
